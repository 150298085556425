var updateLanguageList,
    updateSessionData,
    getSessionData,
    changeRegion,
    changeLanguage,
    ajaxTranslate,
    ALL_URL_TRANSLATIONS,
    ALL_TRANSLATIONS;

$(function(){
    var $regionSelector      = $('#select-region'),
        $languageSelector    = $('#select-language');

    //$('#menu-header .hamburger').click(function(){
    $('#menu-header').on('click', '.hamburger', function(){
        $el = $(this);
        $el.toggleClass('open').prop('disabled', 'disabled');
        setTimeout(function(){
            $el.prop('disabled', false);
        }, 500);     
    });

    $selectedRegion = $('#select-region').find(":selected").val();
    if ($selectedRegion == 'ca' || $selectedRegion == 'us'){
        $('#select-region').css("background-image", "url('/img/icons/flag-"+$selectedRegion+".png')");
        // $('#select-region').css("background-image", "url('/img/icons/flag-us.png')");
    }

    // $('#select-region').css("background-image", "url('/img/icons/flag-us.png')");
    // updateLanguageList = function(el) {
    //     var $el = $(el),
    //     regionID = $el.find(":selected").val();
    //     $.ajax({
    //         type: "POST",
    //         url: window.location.origin+"/inc/ajax_update_language_list.php",
    //         data: 'regionID=' + regionID,
    //         dataType: "html",
    //         success: function(response) {
    //             $selectLanguage = $("#select-language");
    //             $selectLanguage.empty();
    //             $selectLanguage.html(response);
    //         },
    //         error: function(xhr, ajaxOptions, thrownError) {
    //             console.log('updateLanguageList() Error.', thrownError, xhr);
    //         }
    //     });
    // }
    updateSessionData = function(param='', data='', action='add') {
        $.ajax({
            type: "POST",
            url: window.location.origin+"/inc/ajax_update_session_data.php",
            data: 'action='+action+'&param='+param+'&data='+data,
            dataType: "html",
            success: function(response) {
                // console.log('updateSessionData() Success. action=', action, response);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log('updateSessionData() Error.', thrownError, xhr);
            }
        });
    }
    getSessionData = function(callback) {
        $.ajax({
            type: "POST",
            url: window.location.origin+"/inc/ajax_get_session_data.php",
            dataType: "html",
            success: callback,
            // success: function(response) {
            //     console.log('getSessionData() Success.', response);
            //     return response;
            // },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log('getSessionData() Error.', thrownError, xhr);
                return false;
            }
        });
    }

    changeRegion = function(el){
        var regionID = $(el).find(":selected").val();
        //window.location.replace("/en/"+regionID);
        window.location.href = "/en/"+regionID;
    }
    changeLanguage = function(el){
        var languageID = $(el).find(":selected").val() || 'en';
        var regionID = $regionSelector.find(":selected").val() || 'ca';
        var fullLocale = languageID+'-'+regionID;
        var pathname = window.location.pathname;
        var params = window.location.search;

        // console.log('params =', params);
        
        gotoTranslatedURL(pathname, params, languageID, regionID);
    }

    var clean = function(str) {
        str = str.replace(/ /g, '-');             // Replaces all spaces with hyphens.
        str = str.replace(/[^A-Za-z0-9\-]/, '');  // Removes special chars.
        str = str.replace(/-+/g, '-');            // Replaces multiple hyphens with single one.
        return str;
    }

    var updateBreadcrumbs = function(data = []){
        var $container = $('body').find('#breadcrumbs .breadcrumbs-menu');
        if ($container.length){
            $container.empty();            
            $.each(data, function(i, obj){                
                var params = obj.params || '';
                if (typeof obj.text === 'string' || obj.text instanceof String){
                    obj.text = obj.text.replace(/-/g, ' ');
                }
                if (obj.url){
                    $container.append($('<a/>', {href: __(obj.url, true)+params }).text(__(obj.text)));
                } else {
                    $container.append($('<span/>').text(__(obj.text)));
                }
                
            })
        }
    }

    var unique = function(array) {
        return $.grep(array, function(el, index) {
            return index === $.inArray(el, array);
        });
    }

    gotoTranslatedURL = function(pathname, params = "", languageID, regionID){
        //console.log('gotoTranslatedURL('+pathname, params, languageID, regionID,')');
        $.ajax({
            type: "POST",
            url: window.location.origin+"/inc/ajax_translate_url.php",
            data: 'pathname='+pathname+'&languageID='+languageID+'&regionID='+regionID+'&params='+params,
            success: function(response) {
                // console.log('gotoTranslatedURL response:', response);
                window.location.href = response;
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log('ajaxTranslate() Error.', thrownError, xhr);
            }
        });
    }

    var getAllTranslations = function($page){
        // console.log('getAllTranslations()');        
        
        $selectedRegion = $('#select-region').find(":selected").val();
        $.ajax({
            type: 'POST',
            url: window.location.origin+"/inc/ajax_get_all_translations.php",
            data: 'regionID='+$selectedRegion,
            dataType: "json",
            success: function(data){
                ALL_TRANSLATIONS = data;                
                if ($page == "find"){
                    $('body').each(function(){ findPageHandler.call(this); });
                } else if ($page == "selector"){
                    $('body').each(function(){ selectorPageHandler.call(this); });
                } else if ($page == "customize"){
                    $('body').each(function(){ customizePageHandler.call(this); });
                } else if ($page == "estimate"){
                    $('body').each(function(){ estimatePageHandler.call(this); });
                }
            },
            error: function(error){
                console.log("getAllTranslations() Error", error);
            }
        }); 
    }

    var getAllUrlTranslations = function($page){
        // console.log('getAllUrlTranslations()'); 

        $selectedRegion = $('#select-region').find(":selected").val();
        $.ajax({
            type: 'POST',
            url: window.location.origin+"/inc/ajax_get_all_url_translations.php",
            data: 'regionID='+$selectedRegion,
            dataType: "json",
            success: function(data){
                ALL_URL_TRANSLATIONS = data;
                //console.log('ALL_URL_TRANSLATIONS', ALL_URL_TRANSLATIONS);
                //console.log("getAllUrlTranslations() ", data);                
                $('body').each(function(){ getAllTranslations.call(this, $page); });
            },
            error: function(error){
                console.log("getAllUrlTranslations() Error", error);
            }
        }); 
    }

    var getJsonData = function(filename = false, callback){
        if (filename){
            $.ajax({
                dataType: "json",
                url: window.location.origin+"/json/"+filename,
                success: function(response) {
                    //console.log('Success getJsonData('+filename+')');
                    callback(response);                   
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    console.log('getJsonData('+filename+') Error.', thrownError, xhr);
                }
            });
        }
    }

    var __ = function (searchString = "", isURL=false){
        if (isURL){
            inputData = ALL_URL_TRANSLATIONS;
            return translate(searchString, inputData);
        } else {
            inputData = ALL_TRANSLATIONS;
            return translate(searchString, inputData);
        }
    }
    var translate = function (searchString = "", inputData = ALL_TRANSLATIONS){

        var result = searchString;
        if (LANGUAGE != "en"){
            $.each(inputData, function(i, obj){
                if (obj.lang_key == searchString && obj.language.substring(0, 2) == LANGUAGE) {
                    result = obj.translation;
                } 
            })
        }
        return result;
                
        /**
         * Successfully returns partial matches...  CAN USE THIS FOR PRODUCT FILTERING
         * 
        var regexp = new RegExp(searchString, 'i');                                         // create a non casesensitive regex query
        var results = [];
        results.push( inputData.filter(inputData => regexp.test(inputData.lang_key)) );     // returns partial matches
         *
         */

    }

    var updateSidebarValues = function($page){
        // console.log('updateSidebarValues()');

        var $sidebarList        = $page.find('.sidebar-container ul');
        var $sidebarProject     = $sidebarList.find('.sidebar-project'),
            $sidebarFuel        = $sidebarList.find('.sidebar-fuel'),
            $sidebarStyle       = $sidebarList.find('.sidebar-style'),
            $sidebarWidth       = $sidebarList.find('.sidebar-width'),
            $sidebarView        = $sidebarList.find('.sidebar-view'),
            $sidebarInstall     = $sidebarList.find('.sidebar-install'),
            $sidebarFeatures    = $sidebarList.find('.sidebar-features');

        var projectValue    = $('input[name=project]:checked', '#filter').parent('label').text().trim();
        var fuelValue       = $('input[name=fuel]:checked', '#filter').parent('label').text().trim();
        var styleValue      = $('input[name=style]:checked', '#filter').parent('label').text().trim();
        var widthValue      = $('input[name=width]:checked', '#filter').parent('label').text().trim();
        var viewValue       = $('input[name=view]:checked', '#filter').parent('label').text().trim();
        var installValue    = $('input[name=install]:checked', '#filter').parent('label').text().trim();
        var featuresValue   = $('input[name=features]:checked', '#filter').parent('label').text().trim();       

        if(projectValue){
            if (projectValue == "Renovation/New Installation"           || projectValue == "Rénovation/Nouvelle installation") projectValue = "Renovation/New";
            if (projectValue == "Replace Wood Fireplace with an Insert" || projectValue == "Remplacer une foyer à bois par un encastrable") projectValue = "Fireplace Insert";
            if (projectValue == "Outdoor Fireplace Installation"        || projectValue == "Installation d'un foyer extérieur") projectValue = "Outdoor Fireplace";
            $sidebarProject.children('.far').removeClass('fa-circle').addClass('fa-check-circle');
            $sidebarProject.children('.val').text(__(projectValue));
        }
        if(fuelValue){
            $sidebarFuel.children('.far').removeClass('fa-circle').addClass('fa-check-circle');
            $sidebarFuel.children('.val').text(__(fuelValue));
        }
        if(styleValue){
            $sidebarStyle.find('.far').removeClass('fa-circle').addClass('fa-check-circle');
            $sidebarStyle.find('.val').text(__(styleValue));
        }
        if(widthValue){
            $sidebarWidth.find('.far').removeClass('fa-circle').addClass('fa-check-circle');
            $sidebarWidth.find('.val').text(__(widthValue));
        }
        if(viewValue){
            $sidebarView.find('.far').removeClass('fa-circle').addClass('fa-check-circle');
            $sidebarView.find('.val').text(__(viewValue));
        }
        if(installValue){
            $sidebarInstall.find('.far').removeClass('fa-circle').addClass('fa-check-circle');
            $sidebarInstall.find('.val').text(__(installValue));
        }
        if(featuresValue){
            $sidebarFeatures.find('.far').removeClass('fa-circle').addClass('fa-check-circle');
            $sidebarFeatures.find('.val').text(__(featuresValue));
        }
        
    }
   
    var homePageHandler = function() {
        var $page           = $(this);

        $('.slick-slider').slick({
            autoplay: true,
            autoplaySpeed: 1500,
            speed: 800,
            arrows: false,
            fade: true,
            //slidesToShow: 3,
            // slidesToScroll: 1,
            //centerMode: true,
            //variableWidth: true,
            infinite: true
        });

        var removeAnimation = setTimeout(function(){
            $('.dark-bar.animate').removeClass('animate');
            $(window).off("resize");
        }, 6000);

        var onResizeClearAnimation = function() {
            $( window ).resize(function() {
                clearTimeout(removeAnimation);
                $('.dark-bar.animate').removeClass('animate');
                $(window).off("resize");
            });
        }
        onResizeClearAnimation();  
    }  // end - homePageHandler();


    var findPageHandler = function() {
        var $page               = $(this);
        var $sidebarList        = $page.find('.sidebar-container ul');
        var $sidebarCategory    = $sidebarList.find('.sidebar-category'),
            $sidebarType        = $sidebarList.find('.sidebar-type'),
            $sidebarSeries      = $sidebarList.find('.sidebar-series');
        
        var scrollToTarget = function(scrollTarget){
            if ($(scrollTarget).length){
                $('html, body').delay(200).animate({ scrollTop: $(scrollTarget).offset().top  }, 2000, 'easeInOutQuart');
            }
        }
        var url = new URL(window.location.href);
        var searchQuery = url.searchParams.get("search");
        var cat = url.searchParams.get("cat");
        var type = url.searchParams.get("type");
        var series = url.searchParams.get("series");

        var categoryValue = $('input[name=category]:checked', '#filter').parent('label').text();
        var typeValue = $('input[name=type]:checked', '#filter').parent('label').text();
        var seriesValue = $('input[name=series]:checked', '#filter').parent('label').text();

        var animateItems = function($row){
            $.each($row.children('.item'), function(i, el) {
                $(el).delay(i * 75).animate({ opacity: 1, 'margin-top': 0 }, 1000, "easeInOutQuart");
            });
        }

        if (series){                
            // console.log('series');
            $row = $page.find('.row--products');
            $row.addClass('show');
            $row.find('input[type="radio"]').prop('checked', false);

            if($sidebarSeries.length){
                $sidebarCategory.children('.far').removeClass('fa-circle').addClass('fa-check-circle');
                $sidebarCategory.children('.val').text(__(categoryValue));
                $sidebarType.children('.far').removeClass('fa-circle').addClass('fa-check-circle');
                $sidebarType.children('.val').text(__(typeValue));    
                $sidebarSeries.find('.far').removeClass('fa-circle').addClass('fa-check-circle');
                $sidebarSeries.find('.val').text(__(seriesValue));                
            }

            if (window.innerWidth >= 992) { scrollToTarget("#products"); }

            var seriesName = $('input[name=series]:checked').siblings('p').text() || '';
            updateBreadcrumbs([
                { 'text': 'Products', 'url': 'find' },
                { 'text': cat, 'url': 'find', 'params': '?cat='+cat },
                { 'text': type, 'url': 'find', 'params': '?cat='+cat+'&type='+type },
                { 'text': seriesName }
            ]);
            
        } else if (type){
            // console.log('type');
            $row = $page.find('.row--series');
            $row.addClass('show');
            $row.find('input[type="radio"]').prop('checked', false);

            if($sidebarType.length){
                $sidebarCategory.children('.far').removeClass('fa-circle').addClass('fa-check-circle');
                $sidebarCategory.children('.val').text(__(categoryValue));
                $sidebarType.children('.far').removeClass('fa-circle').addClass('fa-check-circle');
                $sidebarType.children('.val').text(__(typeValue));                
            }
            
            updateBreadcrumbs([
                { 'text': 'Products', 'url': 'find' },
                { 'text': cat, 'url': 'find', 'params': '?cat='+cat },
                { 'text': type }
            ]);

        } else if (cat){
            // console.log('cat', categoryValue);
            $row = $page.find('.row--type');
            $row.addClass('show');
            $row.find('input[type="radio"]').prop('checked', false);
            
            if($sidebarCategory.length){
                $sidebarCategory.children('.far').removeClass('fa-circle').addClass('fa-check-circle');
                $sidebarCategory.children('.val').text(__(categoryValue));
            }
            updateBreadcrumbs([
                { 'text': 'Products', 'url': 'find' },
                { 'text': cat }
            ]);
        } else {
            // console.log('default');            
            $row = $page.find('.row--category');
            if (searchQuery) $row = $page.find('.row--search-results');
            $row.addClass('show');
            $page.find('input[type="radio"]').prop('checked', false);
        }
        animateItems($row);

        $page.on('click', '.form-check > .img-container > img', function() {
            $(this).parent().next().children('input').prop("checked", true);
        });
        
        $page.on('click', '.form-check', function() {
            $input = $(this).find('input');
            
            if ($input.attr('name') == "category"){
                window.location.href = location.protocol + '//' + location.host + location.pathname + "?cat="+$input.val();
            } else if ($input.attr('name') == "type"){
                var url = new URL(window.location.href);
                var cat = url.searchParams.get("cat");                
                window.location.href = location.protocol + '//' + location.host + location.pathname + "?cat="+cat+"&type="+$input.val();
            } else if ($input.attr('name') == "series"){
                var url = new URL(window.location.href);
                var cat = url.searchParams.get("cat");             
                var type = url.searchParams.get("type");
                // var seriesName = $(this).find('label p').text() || '';
                // var breadcrumbArray = [
                //     { 'text': 'Products', 'url': 'find' },
                //     { 'text': cat, 'url': 'find', 'params': '?cat='+cat },
                //     { 'text': type, 'url': 'find', 'params': '?cat='+cat+'&type='+type },
                //     { 'text': seriesName }
                // ]
                // setCookie('breadcrumbs', JSON.stringify(breadcrumbArray), 1);
                
                window.location.href = location.protocol + '//' + location.host + location.pathname + "?cat="+cat+"&type="+type+"&series="+$input.val();
            } else if ($input.attr('name') == "product"){

                /* var url = new URL(window.location.href);
                var cat = url.searchParams.get("cat");
                var type = url.searchParams.get("type");
                var series = url.searchParams.get("series");
                //var series = $('input[name=series]:checked');
                var seriesName = $('input[name=series]:checked').siblings('p').text() || '';
                var productName = $(this).find('label p').text() || '';
                var breadcrumbArray = [
                    { 'text': 'Products', 'url': 'find' },
                    { 'text': cat, 'url': 'find', 'params': '?cat='+cat },
                    { 'text': type, 'url': 'find', 'params': '?cat='+cat+'&type='+type },
                    { 'text': seriesName, 'url': 'find', 'params': '?cat='+cat+'&type='+type+'&series='+series },
                    { 'text': productName }
                ]
                setCookie('breadcrumbs', JSON.stringify(breadcrumbArray), 1); */

                window.location.href = __('customize', true) + "?pid="+$input.val();
            }
        });

    }  // end - findPageHandler();

    var selectorPageHandler = function() {
        // console.log('selectorPageHandler()');
        
        var $page               = $(this);
        
        var scrollToTarget = function(scrollTarget){
            if ($(scrollTarget).length){
                ($(scrollTarget).hasClass('row')) ? $offset = -300 : $offset = 0;
                
                $('html, body').delay(600).animate({ scrollTop: $(scrollTarget).offset().top + $offset  }, 2000, 'easeInOutQuart');
            }
        }
        var url = new URL(window.location.href);
        var project = url.searchParams.get("project");
        var fuel = url.searchParams.get("fuel");
        var style = url.searchParams.get("style");
        var width = url.searchParams.get("width");
        var view = url.searchParams.get("view");
        var install = url.searchParams.get("install");
        var features = url.searchParams.get("features");

        var animateItems = function($row){
            $.each($row.children('.item'), function(i, el) {
                $(el).delay(i * 75).animate({ opacity: 1, 'margin-top': 0 }, 1000, "easeInOutQuart");
            });
        }

        updateSidebarValues($page);

        if (features){
            // console.log('features');
            $nextRow = $page.find('.row--products');
            $nextRow.addClass('show');
            // $nextRow.find('input[type="radio"]').prop('checked', false);

            if (window.innerWidth >= 992) { scrollToTarget("#products"); }

            // var featuresName = $('input[name=features]:checked').siblings('p').text() || '';
            // updateBreadcrumbs([
            //     { 'text': 'Help Me Choose', 'url': 'selector' },
            //     { 'text': project, 'url': 'selector', 'params': '?project='+project },
            //     { 'text': fuel, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel },
            //     { 'text': style, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style },
            //     { 'text': width, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style+'&width='+width },
            //     { 'text': view, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style+'&width='+width+'&view='+view },
            //     { 'text': install, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style+'&width='+width+'&view='+view+'&install='+install },
            //     { 'text': featuresName }
            // ]);
            
        } else if (install){                
            // console.log('install');
            // $nextRow = $page.find('.row--features');
            // $nextRow.addClass('show');
            // $nextRow.find('input[type="radio"]').prop('checked', false);

            $nextRow = $page.find('.row--install').nextAll('.row');           // gets next row - some rows may be skipped
            $nextRow.addClass('show');
            if (window.innerWidth >= 992) { scrollToTarget($nextRow); }

            // var installName = $('input[name=install]:checked').siblings('p').text() || '';
            // updateBreadcrumbs([
            //     { 'text': 'Help Me Choose', 'url': 'selector' },
            //     { 'text': project, 'url': 'selector', 'params': '?project='+project },
            //     { 'text': fuel, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel },
            //     { 'text': style, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style },
            //     { 'text': width, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style+'&width='+width },
            //     { 'text': view, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style+'&width='+width+'&view='+view },
            //     { 'text': installName }
            // ]);
            
        } else if (view){                
            // console.log('view');
            // $nextRow = $page.find('.row--install');
            // $nextRow.addClass('show');
            // $nextRow.find('input[type="radio"]').prop('checked', false);

            $nextRow = $page.find('.row--view').nextAll('.row');           // gets next row - some rows may be skipped
            $nextRow.addClass('show');
            if (window.innerWidth >= 992) { scrollToTarget($nextRow); }

            // var viewName = $('input[name=view]:checked').siblings('p').text() || '';
            // updateBreadcrumbs([
            //     { 'text': 'Help Me Choose', 'url': 'selector' },
            //     { 'text': project, 'url': 'selector', 'params': '?project='+project },
            //     { 'text': fuel, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel },
            //     { 'text': style, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style },
            //     { 'text': width, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style+'&width='+width },
            //     { 'text': viewName }
            // ]);
            
        } else if (width){                
            // console.log('width');
            // $nextRow = $page.find('.row--view');
            // $nextRow.addClass('show');
            // $nextRow.find('input[type="radio"]').prop('checked', false);

            $nextRow = $page.find('.row--width').nextAll('.row');           // gets next row - some rows may be skipped
            $nextRow.addClass('show');
            if (window.innerWidth >= 992) { scrollToTarget($nextRow); }

            // var widthName = $('input[name=width]:checked').siblings('p').text() || '';
            // updateBreadcrumbs([
            //     { 'text': 'Help Me Choose', 'url': 'selector' },
            //     { 'text': project, 'url': 'selector', 'params': '?project='+project },
            //     { 'text': fuel, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel },
            //     { 'text': style, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel+'&style='+style },
            //     { 'text': widthName }
            // ]);
            
        } else if (style){                
            // console.log('style');
            // $nextRow = $page.find('.row--width');
            // $nextRow.addClass('show');
            // $nextRow.find('input[type="radio"]').prop('checked', false);

            $nextRow = $page.find('.row--style').nextAll('.row');           // gets next row - some rows may be skipped
            $nextRow.addClass('show');
            if (window.innerWidth >= 992) { scrollToTarget($nextRow); }

            // var styleName = $('input[name=style]:checked').siblings('p').text() || '';
            // updateBreadcrumbs([
            //     { 'text': 'Help Me Choose', 'url': 'selector' },
            //     { 'text': project, 'url': 'selector', 'params': '?project='+project },
            //     { 'text': fuel, 'url': 'selector', 'params': '?project='+project+'&fuel='+fuel },
            //     { 'text': styleName }
            // ]);
            
        } else if (fuel){
            // console.log('fuel');
            // $nextRow = $page.find('.row--style');
            // $nextRow.addClass('show');
            // $nextRow.find('input[type="radio"]').prop('checked', false);

            $nextRow = $page.find('.row--fuel').nextAll('.row');           // gets next row - some rows may be skipped
            $nextRow.addClass('show');
            if (window.innerWidth >= 992) { scrollToTarget($nextRow); }
            
            // updateBreadcrumbs([
            //     { 'text': 'Help Me Choose', 'url': 'selector' },
            //     { 'text': project, 'url': 'selector', 'params': '?project='+project },
            //     { 'text': fuel }
            // ]);

        } else if (project){
            // console.log('project:');
            $nextRow = $page.find('.row--fuel');
            $nextRow.addClass('show');
            // $nextRow.find('input[type="radio"]').prop('checked', false);

            // updateBreadcrumbs([
            //     { 'text': 'Help Me Choose', 'url': 'selector' },
            //     { 'text': project }
            // ]);
        } else {
            // console.log('default');            
            $nextRow = $page.find('.row--project');
            $nextRow.addClass('show');
            // $page.find('input[type="radio"]').prop('checked', false);
        }
        animateItems($nextRow);

        $page.on('click', '.form-check > .img-container > img', function() {
            $(this).parent().next().children('input').prop("checked", true);
        });
        
        $page.on('click', '.form-check', function() {
            $input = $(this).find('input');

            if ($input.attr('name') == "product"){
                window.location.href = __('customize', true) + "?pid="+$input.val();
            } else {
                var projectValue = $page.find('input[name="project"]:checked').val();
                var fuelValue = $page.find('input[name="fuel"]:checked').val();
                var styleValue = $page.find('input[name="style"]:checked').val();
                var widthValue = $page.find('input[name="width"]:checked').val();
                var viewValue = $page.find('input[name="view"]:checked').val();
                var installValue = $page.find('input[name="install"]:checked').val();
                var featuresValue = $page.find('input[name="features"]:checked').val();

                // handles the skipping backwards to previous questions
                if ($input.attr('name') == "project"){ featuresValue = installValue = viewValue = widthValue = styleValue = fuelValue = false; }
                if ($input.attr('name') == "fuel"){ featuresValue = installValue = viewValue = widthValue = styleValue = false; }
                if ($input.attr('name') == "style"){ featuresValue = installValue = viewValue = widthValue = false; }
                if ($input.attr('name') == "width"){ featuresValue = installValue = viewValue = false; }
                if ($input.attr('name') == "view"){ featuresValue = installValue = false; }
                if ($input.attr('name') == "install"){ featuresValue = false; }

                var project     = (projectValue) ? "?project="+projectValue : "";
                var fuel        = (fuelValue) ? "&fuel="+fuelValue : "";
                var style       = (styleValue) ? "&style="+styleValue : "";
                var width       = (widthValue) ? "&width="+widthValue : "";
                var view        = (viewValue) ? "&view="+viewValue : "";
                var install     = (installValue) ? "&install="+installValue : "";
                var features    = (featuresValue) ? "&features="+featuresValue : "";
                
                window.location.href = location.protocol + '//' + location.host + location.pathname+project+fuel+style+width+view+install+features;
            }
        });

    }  // end - selectorPageHandler();

    var customizePageHandler = function() {
        var $page                       = $(this);
        var url                         = new URL(decodeURIComponent(window.location.href)),
            // decodedUrl                  = decodeURIComponent(window.location.href),
            pid                         = url.searchParams.get("pid"),
            urlAssets                   = url.searchParams.getAll("assets[]"),
            assets                      = false,
            product                     = false;
        var $roomsetContainer           = $page.find('.roomset-container'),
            $productContainer           = $page.find('.product-container'),
            $productTitleContainer      = $page.find('.product-title-container');
        var $sidebar                    = $page.find('section#sidebar');
        var $categoriesContainer        = $sidebar.find('.categories-container'),
            $categoriesContainerList    = $sidebar.find('.categories-container>ul'),
            $optionsContainer           = $sidebar.find('.options-container'),
            $optionsContainerList       = $sidebar.find('.options-container>ul');

        // Camera Properties
        var video,                      // used for camera stream
            $videoContainer;

        var display_rules_log           = false,
            categoriesToTurnON          = [],
            categoriesToTurnOFF         = [],
            optionsToTurnON             = [],
            optionsToTurnOFF            = [];
              
        var productsDataHandler = function(data){
            // console.log('productsDataHandler()');

            $.each(data, function(i, el){
                if (el.digital_assets)  assets = el.digital_assets;
                if (el.products) {
                    $.each(el.products, function(n, obj){                        
                        if (obj['salsify:id'] == pid) product = obj;
                    });
                }
            })
            buildCustomizeElements(product);
        } // end - productsDataHandler()
        
        var buildCustomizeElements = function (product = false){
            if (product){
                //console.log('buildCustomizeElements()', product);
                // console.log('assets =', assets[0]['salsify:id']);

                var cat = clean(product['Web Parent Category - Taxonomy']) || '';
                var catName = __(product['Web Parent Category - Taxonomy']) || '';
                var type = clean(product['Web Product Type - EN']) || '';
                var typeName = __(product['Web Product Type - EN']) || '';
                var series = clean(product['Product Series']) || '';
                var seriesName = __(product['Product Series']) || '';
                var productName = product['Web Product Name - '+LANGUAGE.toUpperCase() ] || '';
                var productNote = product['FDS Note - '+LANGUAGE.toUpperCase() ] || '';

                var breadcrumbArray = [
                    { 'text': 'Products', 'url': 'find' },
                    { 'text': catName, 'url': 'find', 'params': '?cat='+cat },
                    { 'text': typeName, 'url': 'find', 'params': '?cat='+cat+'&type='+type },
                    { 'text': seriesName, 'url': 'find', 'params': '?cat='+cat+'&type='+type+'&series='+series },
                    { 'text': productName }
                ];
                updateBreadcrumbs(breadcrumbArray);

                // Build title...
                if ($productTitleContainer.length){
                    $productTitleContainer.empty();
                    $productTitleContainer.append($('<h1/>').text(productName));
                    if(productNote && productNote != ''){
                        $productTitleContainer.append($('<p/>').text(productNote));
                    }
                    $productTitleContainer.append($('<div/>', {class:'instructions'}).text(__('Customize this product by selecting options in the menu.')));
                }


                // Build: Roomset...
                var roomsetAsset = searchAssets('salsify:id', product['Web Room Set Image']);
                $roomsetContainer.append($('<img/>', {src: '/img/assets/' + roomsetAsset['salsify:name'], alt: 'Background image' }));
                var productOffsetY = product['Web FDS Offset Y']    || 0;
                var productScale   = product['Web FDS Scale']       || 1;
                
                // Build Base Layer...
                var baseLayerAsset = searchAssets('salsify:id', product['Web Image FDS Base']);
                $productContainer.data('product-offset-y', productOffsetY);
                $productContainer.data('product-scale', productScale);
                $productContainer.append($('<div/>', {id: 'baseElement', class: 'base-element', style: 'margin-bottom: '+productOffsetY+'%; transform: scale('+productScale+');' })
                    .append($('<img/>', {src: '/img/assets/' + baseLayerAsset['salsify:name'], alt: product['Web Product Name - '+LANGUAGE.toUpperCase()] }))
                );

                // Get Parts and Thumbnail Assets...
                var partsArray      = [],
                    thumbnailsArray = [];
                $.each(product['Web Image FDS Parts'], function (i, el) {
                    var digital_asset = searchAssets('salsify:id', el);
                    if (digital_asset) partsArray.push(digital_asset);
                });
                $.each(product['Web Image FDS Parts Thumb'], function (i, el) {
                    var digital_asset = searchAssets('salsify:id', el);
                    if (digital_asset) thumbnailsArray.push(digital_asset);
                });
                // Adds the Thumbnail's ID to each Part Object (in the partsArray)
                $.each(partsArray, function (i, partObj) {
                    $.each(thumbnailsArray, function (n, thumbObj) { 
                        if (partObj['Web FDS Category - EN'] == thumbObj['Web FDS Category - EN'] && partObj['Web FDS Part Name - EN'] == thumbObj['Web FDS Part Name - EN']){
                            partObj['Thumbnail ID'] = thumbObj['salsify:id'];
                        }
                    });
                });

                //console.log('partsArray =', partsArray);
                
                // Experiment for SORTING Arrays                                    // DOESN'T WORK for names objects  booo :(
                // var sortOrderArray = ["hello",1,2,3,6,5,4,"My Name"];
                // var a = [ 1, 5, 1, 6, "hello", 4, 5, 2, "My Name", "not in sort array", 5, "hello", 4, "not in sort array", 3, 1, "My Name", 2, "neither is this", 6, 6, "My Name", 3, 3, "hello", 2, 4 ];
                // uniqueItems = a.filter(function(item, i, a) {
                //     return i == a.indexOf(item);
                // });
                
                // ------------------------------------------------------------------------------------ //
                // --- Gets & Sorts Categories/Headings ----------------------------------------------- //
                // ------------------------------------------------------------------------------------ //

                    // gets the Category Sort Order (from the product)...
                    var sortOrderArray = product['Web FDS Category Order - EN'];
                    // console.log('sortOrderArray =', sortOrderArray);
                                        
                    // --- GET UNIQUE Part Categories/Headings - from individual parts ---------------- //
                    var partsSortKey = 'Web FDS Category - EN';
                    var uniqueItems = [...new Map(partsArray.map(item => [item[partsSortKey], item])).values()];         // WORKS FOR NAMES OBJECTS!!!
                    // tempArray = [];      uniqueItems.forEach(element => tempArray.push(element[partsSortKey]));       console.log('Unique items, unsorted: ', tempArray);

                    if (sortOrderArray){
                        // --- SORT the UNIQUE Part Categories/Headings based on "sortOrderArray" --------- //
                        uniqueItems.sort(function(a, b){
                            return sortOrderArray.indexOf(a[partsSortKey]) - sortOrderArray.indexOf(b[partsSortKey]);
                        });
                        // tempArray = [];      uniqueItems.forEach(element => tempArray.push(element[partsSortKey]));       console.log('Unique items, SORTED: ', tempArray);

                        // --- GETS any NEW Part Categories/Headings that ARE NOT in "sortOrderArray" ----- //
                        var missing = uniqueItems.filter( function(i) { return this.indexOf(i[partsSortKey]) < 0; }, sortOrderArray );
                        // tempArray = [];      missing.forEach(element => tempArray.push(element[partsSortKey]));           console.log('missing items: ', tempArray);
                    } else {
                        console.log('WARNING: Missing value: "Web FDS Category Order - EN". Cannot sort categories.');
                    }

                    // --- ADD NEW Missing Categories/Headings to the end of the Array ---------------- //
                    $.each(missing, function (i, el) { 
                        uniqueItems.push(uniqueItems.splice(uniqueItems.indexOf(el),1)[0]);
                    });
                    var partsCategories = [];     uniqueItems.forEach(element => partsCategories.push(element[partsSortKey]));
                    //console.log('Unique items, RESORTED: ', partsCategories);
                
                // ------------------------------------------------------------------------------------ //
                // --- BUILD the Categories ----------------------------------------------------------- //
                // ------------------------------------------------------------------------------------ //
                $.each(partsCategories, function (i, category) {
                    $categoriesContainerList.append($('<li/>', {'data-category': category})
                        .append($('<a/>', {href: 'javascript:;', 'data-category':category, 'data-order':i, role: 'presentation' }).text(__(category)))
                    );
                });
                // ------------------------------------------------------------------------------------ //
                // --- BUILD the Options -------------------------------------------------------------- //
                // ------------------------------------------------------------------------------------ //
                if (sortOrderArray){
                    partsArray.sort(function(a, b){
                        return sortOrderArray.indexOf(a[partsSortKey]) - sortOrderArray.indexOf(b[partsSortKey]);
                    });
                }
                $.each(partsArray, function (i, part) {
                    var $thumbnailAsset;
                    if (part['Thumbnail ID']){
                        $thumbnailAsset = searchAssets('salsify:id', part['Thumbnail ID']);
                    } else {
                        // Thumnbail not found. Use the part image instead.
                        console.log('Warning. "'+part['Web FDS Part Name - EN']+'" ('+part['Web FDS Category - EN']+') has no matching thumbnail image. Check that the thumbnail\'s name and category matches the part.');
                        $thumbnailAsset = part;
                    }
                    var categoryOrder = $categoriesContainerList.find('a[data-category="'+part['Web FDS Category - EN']+'"]').data('order') || 0;

                    //console.log("part['Thumbnail ID'] =", part['Thumbnail ID']);
                    //console.log('$thumbnailAsset =', $thumbnailAsset);
                    
                    // part['Web FDS Rules - Mandatory Part'];
                    // part['Web FDS Standard Part'];
                    var optionDataObject = { 'data-order': categoryOrder, 'data-category': part['Web FDS Category - EN'], 'data-part-id':part['FDS Part ID']}
                    if (part['Web FDS Standard Part'])                      optionDataObject['data-standard'] = 'standard';
                    if (part['Web FDS Offset Y'])                           optionDataObject['data-part-offset-y'] = part['Web FDS Offset Y'];
                    if (part['Web FDS Rules - Mandatory Part'])             optionDataObject['data-mandatory-part'] = part['Web FDS Rules - Mandatory Part'];
                    if (part['Web FDS Rules - Mandatory Category'])         optionDataObject['data-mandatory-category'] = part['Web FDS Rules - Mandatory Category'];
                    if (part['Web FDS Rules - Non-Compatible Part'])        optionDataObject['data-non-compatible-part'] = part['Web FDS Rules - Non-Compatible Part'];
                    if (part['Web FDS Rules - Non-Compatible Category'])    optionDataObject['data-non-compatible-category'] = part['Web FDS Rules - Non-Compatible Category'];

                    
                    $optionsContainerList.append($('<li/>', optionDataObject)
                        .append($('<a/>', { href: 'javascript:;', 'data-part-id':part['FDS Part ID'], 'data-part-asset-id':part['salsify:id'], role: 'presentation' })
                            .append($('<div/>', { class: 'image-frame' })
                                .append($('<img/>', {src: '/img/assets/'+$thumbnailAsset['salsify:name'], alt: part['Web FDS Part Name - EN'] }))
                            )
                            .append($('<p/>').text(part['Web FDS Part Name - '+LANGUAGE.toUpperCase()]))
                        )
                    );
                });
                
                setupEventListeners();
                
                function getSessionResponse(response){
                    if (response){
                        var data = JSON.parse(response);
                        if (data.product == product['Part ID'] && data.asset_ids){
                            activateSessionAssets(data.asset_ids, true);                // returning back to product, re-activate previous assets/parts...
                        } else {
                            activateStandardEquipment();                                // New product... activate standard equipment...
                        }
                    }
                }
                function getAndWaitForSessionData(callback) {
                    getSessionData(function(response){
                        getSessionResponse(response);
                    })
                }
                if (urlAssets.length > 0){
                    // Get layer data from URL, and turn on those layers...                    
                    activateSessionAssets(urlAssets, false);                            // returning back to product, re-activate previous assets/parts...
                } else {
                    // Check for session data, and turn on those layers...
                    getAndWaitForSessionData();
                }

                // ------------------------------------------------------------------------------------ //
                // --- Camera - Detect Camera, if available, build buttons ---------------------------- //
                // ------------------------------------------------------------------------------------ //
                
                CameraStreamInit({initial:true});

                // --- Camera - Detect Camera --------------------------------------------------------- //
                
                
            } else {
                console.log("Sorry we couldn't find the product you're looking for: ", pid);
            }
        } // end - buildCustomizeElements()

        // ------------------------------------------------------------------------------------ //
        // --- Event Listeners ---------------------------------------------------------------- //
        // ------------------------------------------------------------------------------------ //
        var setupEventListeners = function(){
            //console.log('setupEventListeners()');

            $sidebar.on('click', '.btn-reset', function(){                
                updateSessionData('', '', 'clear');
                var url = window.location.origin + window.location.pathname + '?pid='+pid;
                window.location.href = url;
            });
            
            // Categories...
            $categoriesContainerList.on('click', 'li:not(.active) a', function() {
                var $thisCategory       = $(this).parent('li'),
                    categoryName        = $(this).data('category');

                //clearTimeout(closeOptionsTimer);
        
                $categoriesContainerList.find('li').removeClass('active');
                $thisCategory.addClass('active');
        
                // Deactivate/Activate Options Groups...
                $optionsContainerList.parent('ul').scrollTop(0);
                $optionsContainerList.find('li').removeClass('active');
                var $activeOptions = $optionsContainerList.find('li[data-category="' + categoryName + '"]');
                $activeOptions.addClass('active');
        
                $sidebar.addClass('expanded');
                $('.instructions').addClass('hide');
                animateOptions($activeOptions);
            });
            $optionsContainer.on('click', '.hamburger', function(){
                if (window.innerWidth >= 992) { 
                    $categoriesContainerList.find('li').removeClass('active');
                    $sidebar.removeClass('expanded');
                    $('.instructions').removeClass('hide');
                } else {
                    $sidebar.removeClass('show-menu');
                }
            });
            // Options...
            $optionsContainer.on('click', 'li', function(){
                
                // console.clear();
                var $option         = $(this);
                // --- small indicator for the category ---- //
                    var categoryName    = $option.data('category');
                    var $category       = $categoriesContainerList.find('li[data-category="'+categoryName+'"]');
                    if (!$option.hasClass('current')) {                         // in the process of ACTIVATING this option          
                        if ($category.length) $category.addClass('option-selected');
                    } else {                                                    // in the process of DEACTIVATING this option
                        var optionsInSameCategory = $optionsContainer.find('li[data-category="'+categoryName+'"].current');
                        if (optionsInSameCategory.length == 1){                 // if this is the only option that's activated...
                            $category.removeClass('option-selected');
                        }
                    }
                // ----------------------------------------- //
                toggleOptionWithoutRules( $option );
                checkRules({ triggerElement: $option });
                var $sidebar = $('#sidebar');
                if ($sidebar.length){
                    if ($sidebar.hasClass('show-menu')){
                        setTimeout(function(){
                            $sidebar.removeClass('show-menu');
                        }, 150);
                    }
                }
                
                $selectedOptions = $optionsContainerList.find('li.current');
                if ($selectedOptions.length){
                    var dataObj = [];
                    $.each($selectedOptions, function(i, el){
                        var partAssetID = $(el).children('a').data('part-asset-id');
                        if (partAssetID.length) dataObj.push(partAssetID);
                    });
                    updateSessionData('product', pid, 'add');
                    updateSessionData('asset_ids', JSON.stringify(dataObj), 'add');
                }
                
            });

            $('.mobile-menu-bottom').on('click', '.toggle-sidebar', function(){                    
                if ($optionsContainerList.find('li.active').length == 0){
                    $categoriesContainerList.find('li').first().children('a').trigger('click');
                }
                $sidebar.addClass('show-menu');
            });
            
            // ------------------------------------------------------------------------------------ //
            // --- Camera - Events start ---------------------------------------------------------- //
            // ------------------------------------------------------------------------------------ //
            $roomsetContainer
            .on('click', '.camera--init', function(){
                // console.log('Initialize Camera');
                $videoContainer = $roomsetContainer.find('.video-container');                
                CameraStreamInit();
            })
            .on('click', '.camera--kill', function(){
                // console.log('Kill Camera Stream', video.srcObject);
                CameraStreamKill();
            })
            .on('click', '.camera--pause', function(){
                // console.log('Pause Camera Stream');
                CameraStreamPause();
            })
            .on('click', '.camera--play', function(){
                // console.log('Play Camera Stream');
                CameraStreamPlay();
            });
            
        } // end - setupEventListeners()
        // ------------------------------------------------------------------------------------ //


        var buildCameraButtons = function(){
            $roomsetContainer
            .append($('<div/>', {class:'video-container'}))
            .append($('<div/>', {class: 'camera-buttons'})
                .append(
                    $('<a/>', {class: 'btn btn-danger btn-sm camera--init', href:'javascript:;'})
                    .append($('<span/>', {class: 'd-none d-md-inline'}).html(__('Camera')+'&nbsp;'))
                    .append($('<i/>', {class: 'fas fa-video'}))
                    )
                    .append(
                    $('<a/>', {class: 'btn btn-dark btn-sm camera--kill hidden', href:'javascript:;'})
                    .append($('<span/>', {class: 'd-none d-md-inline'}).html(__('Camera Off')+'&nbsp;'))
                    .append($('<i/>', {class: 'fas fa-video-slash'}))
                )
                .append(
                    $('<a/>', {class: 'btn btn-dark btn-sm camera--pause hidden', href:'javascript:;'})
                    .append($('<i/>', {class: 'fas fa-pause'}))
                )
                .append(
                    $('<a/>', {class: 'btn btn-dark btn-sm camera--play hidden', href:'javascript:;'})
                    .append($('<i/>', {class: 'fas fa-play'}))
                )
            );
        }
        // ------------------------------------------------------------------------------------ //
        // --- Camera - Functions start ------------------------------------------------------- //
        // ------------------------------------------------------------------------------------ //
                
        var stopCam = function(stream=false){
            // console.log('Stop Camera Stream');
            if (stream){
                stream.getTracks().forEach(function(track) {
                    track.stop();
                });
            } else {
                video.srcObject.getTracks().forEach(function(track) {
                    track.stop();
                    video.srcObject.removeTrack(track);
                });
                video.srcObject = null;
                $videoContainer.empty();
                $('.camera--init').toggleClass('hidden');
                $('.camera--kill, .camera--pause, .camera--play').addClass('hidden');
            }
        }

        var showCam = function(stream, initial=false) {
            // console.log('showCam(stream, initial)', stream, initial);
            if (initial){
                // console.log('Camera is available.');
                stopCam(stream);
                buildCameraButtons();
            } else {
                video = document.createElement("video");
                video.setAttribute("playsinline", "");
                video.setAttribute("autoplay", "");
                video.setAttribute("muted", "");
                
                // let video = document.querySelector("video");
                video.srcObject = stream;
                $videoContainer.append(video);
                $('.camera--init').addClass('hidden');
                $('.camera--kill, .camera--pause').removeClass('hidden');
                $('.camera--play').addClass('hidden');

                $('.product-container').draggableTouch();
            }
        }
        var showCamError = function(err) {
            let message = err.name === "NotFoundError" ? "Please Attach Camera" :
                err.name === "NotAllowedError" ? "To use camera, please grant permission to access camera" : err;
            //alert("showCamError\n"+message);
            console.log('Camera Warning:', message);
        }

        var CameraStreamInit = function(obj={}) {
            (obj.initial) ? initial = obj.initial : initial = false;

            if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
                navigator.userMedia = navigator.mozGetUserMedia || navigator.getUserMedia;
                if (!navigator.userMedia) {
                    message = "It appears that your browser is not capable of displaying a camera feed. In order to use your camera, please update or use different browser.";
                    //alert(message);
                    console.log("Initialize Camera Warning:", message);
                    return;
                }
                navigator.userMedia({
                    audio: false,
                    video: {
                        facingMode: "environment" // front facing = "user" 
                    }
                }, (stream) => showCam(stream, initial), (err) => showCamError(err))
                return;
            }

            navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                        facingMode: "environment" // front facing = "user" 
                    }
                })
                .then((stream) => showCam(stream, initial))
                .catch((err) => showCamError(err))
        }


        var CameraStreamKill = function(stream=false){
            // console.log('Kill Camera Stream', video.srcObject);
            if (stream){
                stream.getTracks().forEach(function(track) {
                    track.stop();
                });
            } else {
                video.srcObject.getTracks().forEach(function(track) {
                    track.stop();
                    video.srcObject.removeTrack(track);
                });
                video.srcObject = null;
            }
            $videoContainer.empty();
            $('.camera--init').toggleClass('hidden');
            $('.camera--kill, .camera--pause, .camera--play').addClass('hidden');

            $('.product-container')
                .draggableTouch("disable")
                .css({"top":"auto","left":"0"});
        };
        var CameraStreamPause = function(){
            video.pause();
            $('.camera--pause').addClass('hidden');
            $('.camera--play').removeClass('hidden');
        }
        var CameraStreamPlay = function(){
            video.play();
            $('.camera--pause').removeClass('hidden');
            $('.camera--play').addClass('hidden');
        }
        // --- Camera - Functions end ----------------//--------------------------------------- //
        
        var activateSessionAssets = function(asset_ids_input, isString=true){
            //console.log('activateSessionAssets()', 'isString =', isString, asset_ids_input);
            var asset_ids;
            if (isString){
                asset_ids = JSON.parse(asset_ids_input);
            } else {
                asset_ids = asset_ids_input;
            }
            $.each(asset_ids, function(i, id){
                var $allOptions = $optionsContainerList.find('li');
                $.each($allOptions, function(n, el) {
                    var $option = $(el);
                    var $btn = $option.children('a').eq(0);
                    if ($btn.data('part-asset-id') == id) $option.trigger("click");
                });
            });
            // $standardOptions = $optionsContainerList.find('li[data-standard]');
            checkRules();
        }

        var activateStandardEquipment = function(){
            // console.log('activateStandardEquipment()');
            
            var $allOptions = $optionsContainerList.find('li');
            // first, activate all the standard options that DON'T have manadatory parts...
            $.each($allOptions, function(i, el) {
                var $option = $(el);
                if ($option.data('standard') && !$option.data('mandatory-part')) {
                    // console.log('Standard option =', $option.data('category'), '  >>  "'+$option.find('p').text()+'"');
                    $option.trigger("click");
                }
            });
            // second, activate all the standard options that DO have manadatory parts...
            $.each($allOptions, function(i, el) {
                var $option = $(el);
                if ($option.data('standard') && $option.data('mandatory-part')) {
                    // console.log('Standard option =', $option.data('category'), '  >>  "'+$option.find('p').text()+'"');
                    $option.trigger("click");
                }
            });
            $standardOptions = $optionsContainerList.find('li[data-standard]');
            if (!$standardOptions) checkRules();
        }

        var toggleOptionWithoutRules = function( $option ){
            // console.log('toggleOptionWithoutRules(), $option:', $option);
            var $optionAnchor = $option.find('a');
            var category      = $option.data('category') || "";
            var order         = $option.data("order") ? $option.data("order") : 0;
            var $asset        = searchAssets('salsify:id', $optionAnchor.data("part-asset-id"));
            
            if ($option.hasClass('current')){
                // Already current. Deactivate and remove from product part layers
                $option.removeClass('current');
                $productContainer.find('[data-category="'+category+'"]').remove();
                applyScaleAndPosition();
            } else {
                // deactivate other options (under same heading). activate this option
                $optionsContainerList.find('li[data-category="'+category+'"]').removeClass('current');
                $option.addClass('current');

                var $elementExists = $productContainer.find("[data-category='" + category + "']");
                if ($elementExists.length){
                    // Part heading element exists - REPLACE the content...
                    $elementExists.data('category', $optionAnchor.data("category"));
                    $elementExists.data('heading_title', $optionAnchor.data("heading_title"));
                    $elementExists.data('part-id', $optionAnchor.data("part-id"));
                    $elementExists.find('img').attr('src', '/img/assets/'+$asset['salsify:name']);
                    $elementExists.find('img').attr('data-part-id', $optionAnchor.data("part-id"));
                } else {
                    // Part heading element doesn't exist - CREATE the element...
                    $productContainer.append($('<div/>', {class: 'element order-'+order, 'data-category': $option.data("category"), 'data-part-id': $optionAnchor.data("part-id")})
                        .append($('<img/>', {src: '/img/assets/'+$asset['salsify:name'], 'data-part-id': $optionAnchor.data("part-id")})
                        )
                    );
                }
                applyScaleAndPosition();
            }
        }

        var checkRules = function(obj = {}){
            // if (display_rules_log) console.log('// ------------------------------------------------ //');
            // if (display_rules_log) console.log('checkRules()');
            var $triggerElement       = (obj.triggerElement)        ? obj.triggerElement : false;

            var tab                   = '\t',
                $allOptions           = $optionsContainerList.find('li'),
                $currentOptions       = $optionsContainerList.find('li.current');
                // $currentCategories    = $categoriesContainerList.find('li.option-selected');
            
            categoriesToTurnOFF       = [];   // reset
            categoriesToTurnON        = [];   // reset
            optionsToTurnOFF          = [];   // reset
            optionsToTurnON           = [];   // reset
            
            // --- NON-COMPATIBLE-CATEGORY - (PRE-CHECK) - -------------------------------------------- //
            if ($triggerElement && $triggerElement.hasClass('current')){
                // this option WAS OFF, is now turning ON
                var nonCompatibleCategory = $triggerElement.data('non-compatible-category') || false;
                if (nonCompatibleCategory){                    
                    nonCompatibleCategoryArray = nonCompatibleCategory.split(',');
                    $.each(nonCompatibleCategoryArray, function(n, category){
                        // if non-compatible-category(s) exist, deactivate them...                        
                        if($.inArray(category, categoriesToTurnOFF) == -1){
                            categoriesToTurnOFF.push(category);
                            // disable all options that are also dependant on this category...
                            $.each($currentOptions, function(i, el){
                                var $option = $(el);
                                if ($option.data('category') == category) {
                                    // IMMEDIATELY Deactivate all options that are also dependant on this category...
                                    //console.log('--------------- IMMEDIATELY Deactivate:', '\t\t'+$option.data('category'), '  >>  "'+$option.find('p').text()+'"');
                                    $option.attr('disabled', 'disabled');
                                    if ($option.hasClass('current')) toggleOptionWithoutRules( $option );
                                }
                            });
                        }
                    });
                }
            }
            

            $.each($allOptions, function(i, el) {
                var $option = $(el);

                // ---------------------------------------------------------------------------------- //
                // --- MANDATORY PART --------------------------------------------------------------- //
                var mandatoryPart = $option.data('mandatory-part') || false;
                if (mandatoryPart){
                    if ($currentOptions.find('[data-part-id="'+mandatoryPart+'"]').length) {
                        //optionsToTurnON.push($option);
                    } else {
                        optionsToTurnOFF.push($option);
                    }
                }

                // ---------------------------------------------------------------------------------- //
                // --- MANDATORY CATEGORY ----------------------------------------------------------- //
                var mandatoryCategory = $option.data('mandatory-category') || false;
                if (mandatoryCategory){
                    // console.log('mandatoryCategory =', mandatoryCategory, $allOptions.find('[data-category="'+mandatoryCategory+'"]').length);
                    var categoryIsActive = false;
                    $.each($currentOptions, function(i, el){
                        if ($(el).data('category') == mandatoryCategory) categoryIsActive = true;
                    });
                    if (!categoryIsActive) {
                        optionsToTurnOFF.push($option);
                    }
                }
            }); // end - $.each($allOptions)
            
            //console.log('All Current Options...');
            
            $.each($currentOptions, function(i, el) {
                $currentOption = $(el);

                //console.log('$currentOption =', $currentOption.data('category'), '>>', $currentOption.find('p').text());
                var currentOptionIsToBeTurnedOFF = false;
                $.each(optionsToTurnOFF, function(i, el){
                    var opt = $(el);
                    //console.log('       - ', opt.data('category')+':', (opt.data('part-id')) ? opt.data('part-id'): 'standard', '>>', opt.find('p').text(), opt );
                    if (opt.data('category') == $currentOption.data('category') && opt.find('p').text() == $currentOption.find('p').text()){
                        currentOptionIsToBeTurnedOFF = true;
                        return false;
                    }
                })
                //console.log('currentOptionIsToBeTurnedOFF =', currentOptionIsToBeTurnedOFF);
                
                if (currentOptionIsToBeTurnedOFF == false){
                    
                    // ---------------------------------------------------------------------------------- //
                    // --- NON-COMPATIBLE-PART(s) ------------------------------------------------------- //
                    var nonCompatibleParts = $currentOption.data('non-compatible-part') || false;
                    if (nonCompatibleParts){
                        nonCompatiblePartsArray = nonCompatibleParts.split(',');
                        $.each(nonCompatiblePartsArray, function(n, partID){
                            var target = $allOptions.find('[data-part-id="'+partID+'"]').parent('li');
                            if (target.length) {
                                optionsToTurnOFF.push(target);
                            }
                        });
                    }

                    // ---------------------------------------------------------------------------------- //
                    // --- NON-COMPATIBLE-CATEGORY(s) - (secondary check) ------------------------------- //
                    var nonCompatibleCategory = $currentOption.data('non-compatible-category') || false;
                    if (nonCompatibleCategory){
                        //console.log('Non Compatible Category triggered on part:', '\t\t'+$currentOption.data('category'), '  >>  "'+$currentOption.find('p').text()+'"');               
                        nonCompatibleCategoryArray = nonCompatibleCategory.split(',');
                        $.each(nonCompatibleCategoryArray, function(n, category){
                            // if non-compatible-category(s) exist, deactivate them...
                            if($.inArray(category, categoriesToTurnOFF) == -1){
                                categoriesToTurnOFF.push(category);
                                // disable all options that are also dependant on this category...
                                $.each($allOptions, function(i, el){
                                    if ($(el).data('mandatory-category') == category) optionsToTurnOFF.push($(el));
                                });
                            }
                        });
                    }
                    
                }
            }); // end - $.each($currentOptions)
            
            // ---------------------------------------------------------------------------------- //
            // --- Last Check ------------------------------------------------------------------- //
            // If all options within a category are turned off... turn of the category too.
            // get the Categories from the options to be turned off
            
            var uniqueCategories = [];
            $.each(optionsToTurnOFF, function(i, el){
                var opt = $(el);
                if (opt.data('category')) uniqueCategories.push(opt.data('category'));

            });
            uniqueCategories = unique(uniqueCategories);
            
            //var uniqueCategories = [...new Map(optionsToTurnOFF.map(item => [item['data-category'], item])).values()]; // DOESN'T WORK
            $.each(uniqueCategories, function(i, category) {
                //var category = $option.data('category');
                var totalOptionsInCategory = $optionsContainerList.find('[data-category="'+category+'"]').length || 0;
                var optionsToBeTurnedOffInCategory = optionsToTurnOFF.reduce(function (r, a) { return r + +(a.data('category') === category); }, 0);
                if (optionsToBeTurnedOffInCategory >= totalOptionsInCategory){
                    categoriesToTurnOFF.push(category);
                }
            });
            
            applyRules();
        } // end - checkRules()

        var applyRules = function(){
            // ------------------------------------------------------------------
            // Turns off the appropiate headings and options within
            if (display_rules_log) console.log('// ----------------------------- //');
            if (display_rules_log) console.log('applyRules() Rules:');
            if (display_rules_log && categoriesToTurnOFF.length) {
                console.log('   categoriesToTurnOFF:');
                $.each(categoriesToTurnOFF, function(i, category){
                    console.log('     - ', category);
                });
            }
            if (display_rules_log && optionsToTurnOFF.length) {
                console.log('   optionsToTurnOFF:');
                $.each(optionsToTurnOFF, function(i, el){
                    var opt = $(el);
                    console.log('     - ', opt.data('category')+':', (opt.data('part-id')) ? opt.data('part-id'): 'standard', '>>', opt.find('p').text(), opt );
                })
            }
      
            $optionsContainerList.find('li').attr('disabled', false);                                  // turns ON all options first
            $.each(optionsToTurnOFF, function(i, $option) {

                $thisOptionsCategory = $categoriesContainerList.find('li[data-category="'+$option.data('category')+'"]');
                $otherOptionsInSameCategory = $optionsContainerList.find('li[data-category="'+$option.data('category')+'"]').hasClass('current');
                if ($otherOptionsInSameCategory.length){
                    // if no other options in the same category are selected... the turn off the category indicator
                    if ($thisOptionsCategory.length){
                        if ($thisOptionsCategory.hasClass('option-selected')) {
                            console.log('removing option-selected');
                            
                            $thisOptionsCategory.removeClass('option-selected');
                        }
                    }
                }
                $option.attr('disabled', 'disabled');
                if ($option.hasClass('current')) toggleOptionWithoutRules( $option );                  // turns off options that were already currently selected
                
            });

            $categoriesContainerList.find('li').attr('disabled', false);                               // turns ON all categories first
            $.each(categoriesToTurnOFF, function(i, category) {
                var $category = $categoriesContainerList.find('li[data-category="'+category+'"]');
                if ($category.length) $category.removeClass('option-selected');

                var $currentOptions = $optionsContainerList.find('li.current[data-category="'+category+'"]');
                $.each($currentOptions, function(i, el) {
                    $option = $(el);
                    toggleOptionWithoutRules( $option );
                });
                
                var $category = $categoriesContainerList.find('li[data-category="'+category+'"]'); 
                $category.attr('disabled', 'disabled');
            });

        } // end applyRules()


        var animateOptions = function($item){
            $.each($item, function(i, el) {
                $(el).attr('style', '');
                $(el).delay(i * 75).animate({ opacity: 1, 'padding-top': 0 }, 1000, "easeInOutQuart");
            });
        }

        //searchAssets('salsify:id', product['Web Room Set Image']);
        var searchAssets = function(property, value){
            var returnedData = $.grep(assets, function (obj, i) {
                return obj[property] == value;
            });
            return returnedData[0];
        }          

        var applyScaleAndPosition = function(){
            var part_collective_offsetY = 0;
      
            // get all part offsets...
            var $allOptionsCurrent  = $optionsContainerList.find('li.current');
      
            $.each($allOptionsCurrent, function(i, el) {
              var part_offset_y = Number($(el).data('part-offset-y')) || 0;
              part_collective_offsetY += part_offset_y;
            });
            // Apply Offset Y
            var product_offsetY = Number($productContainer.data('product-offset-y')) || 0;
            var offset = product_offsetY + part_collective_offsetY;
            // console.log('product_offsetY =', product_offsetY);
            // console.log('part_collective_offsetY =', part_collective_offsetY);
            // console.log('Total Offset =', offset);
            
            $productContainer.children('.base-element').css({'margin-bottom': offset+"%"});
      
            // Apply Scale
            var product_scale = Number($productContainer.data('product-scale')) || 1;
            $productContainer.children('div').css({'transform': "scale("+product_scale+")"});
        }

        if (pid){
            getJsonData('fds-products-condensed.json', productsDataHandler);            // Load JSON data with callback
            // getJsonData('fds-products-condensed-FORMATTED-2.json', productsDataHandler);            // Load JSON data with callback
        }

    }  // end - customizePageHandler();

    var estimatePageHandler = function(){
        var $page           = $(this);
        var $form           = $page.find('form.needs-validation');
        var $dealerSlider   = $page.find('#recipient-dealer');        
        
        var getClosestDealers = function(lat=false, lon=false){
            $selectedRegion = $('#select-region').find(":selected").val().toUpperCase();
            $.ajax({
                type: 'POST',
                url: window.location.origin+"/inc/ajax_get_closest_dealers.php",
                data: 'region='+$selectedRegion,
                dataType: "json",
                success: function(data){
                    console.log('getClosestDealers: ', data);
                    
                    buildDealerSelections(data);
                    activateDealerButtons();
                },
                error: function(error){
                    console.log("getClosestDealers() Error", error);
                }
            }); 
        }
        var resetDealerSlider = function(){
            if ($dealerSlider.hasClass('slick-slider')) {
                $dealerSlider.slick('unslick');
                $dealerSlider.empty().removeAttr("style");
            }
        }

        $form.on('change', 'input[type=checkbox]#cc_me', function(){
            $el = $(this);
            var isActive = this.checked;
            $form.removeClass('was-validated');
            $collapseTarget = $form.find($el.data('bs-target'));
            if ($collapseTarget.length){
                var $conditionalInputs = $collapseTarget.find('.conditional-required');
                $.each($conditionalInputs, function(i, el){
                    if (isActive){
                        $(el).prop('required',true).prop('disabled', false);
                    } else {
                        $(el).prop('required',false).prop('disabled', 'disabled');
                    }
                });
            }
        });

        $page.on('click', 'a.send-to-friend', function(){
            var $recipientEmail = $form.find('#recipient-email');
            if ($recipientEmail.length){
                var $emailInput = $recipientEmail.find('input#to_email');
                if ($emailInput.length) $emailInput.prop('required',true).prop('disabled', false);
                $recipientEmail.show();
            }
            var $sendToFriendFields = $form.find('.send-to-friend-fields');
            if ($sendToFriendFields.length){
                $('textarea#to_message').prop('disabled', false);
                $sendToFriendFields.show();
            }
            var $sendToDealerFields = $form.find('.send-to-dealer-fields');
            if ($sendToDealerFields.length){
                $('textarea#message_for_dealer').prop('disabled', 'disabled');
                $sendToDealerFields.hide();
            }
            if ($('input#cc_me').attr('aria-expanded') == 'true'){
                $('input#cc_me').trigger('click');
            }
            $form.find('input[name="dealer_id"]').val('');
            $form.find('button[type="submit"]').prop('disabled', false);
            resetDealerSlider();
        });

        $page.on('click', 'a.send-to-dealer', function(){
            var $recipientEmail = $form.find('#recipient-email');
            if ($recipientEmail.length){
                var $emailInput = $recipientEmail.find('input#to_email');
                if ($emailInput.length) $emailInput.prop('required',false).prop('disabled', 'disabled');
                $recipientEmail.hide();
            }

            var $sendToDealerFields = $form.find('.send-to-dealer-fields');
            if ($sendToDealerFields.length){
                $('textarea#message_for_dealer').prop('disabled', false);
                $sendToDealerFields.show();
            }
            var $sendToFriendFields = $form.find('.send-to-friend-fields');
            if ($sendToFriendFields.length){
                $('textarea#to_message').prop('required',false).prop('disabled', 'disabled');
                $sendToFriendFields.hide();
            }
            if ($('input#cc_me').attr('aria-expanded') == 'true'){
                $('input#cc_me').trigger('click');
            }
            $form.find('button[type="submit"]').prop('disabled', 'disabled');

            getClosestDealers();
        });
        
        var buildDealerSelections = function(data){
            // console.log('buildDealerSelections()', data);
            resetDealerSlider();
            $.each(data, function(i, dealer){
                distance = (Math.round(dealer.distance * 10) / 10);                     // round to 1 decimal place
                distance_unit = "km";
                if (dealer.country.toLowerCase() == "us"){
                    distance = (Math.round((dealer.distance * 0.62137) * 10) / 10);     // convert to miles and round to 1 decimal place
                    distance_unit = "mi";                    
                }
                var type_title = dealer.type_title_en;
                var button_text = "Select Dealer";
                if(LANGUAGE == 'fr'){
                    type_title = dealer.type_title_fr;
                    button_text = "Sélectionner";
                }
                $dealerSlider.append($('<div/>', { class: 'dealer text-center' })
                    .append($('<div/>', { class:'dealer-type-wrapper' })
                        .append($('<img/>', {src:"/img/icons-dealer/"+dealer.type_image, alt: type_title}))
                        .append($('<span/>').text(type_title))
                    )
                    .append($('<p/>', {class: 'name' })
                        .append($('<b/>').text(dealer.company_name))
                    )
                    .append($('<p/>', {class: 'location' }).text(dealer.city+' - '+distance+' '+distance_unit))
                    .append($('<p/>')
                        .append($('<a/>', {class:'btn btn-link text-danger', href:'tel:'+dealer.phone}).text(dealer.phone))
                    )
                    .append($('<p/>')
                        .append($('<a/>', {class:'btn btn-danger select-dealer', 'data-id':dealer.locator_id }).text(button_text))
                    )
                    // .append($('<p/>')
                    //     .append($('<small/>').text(dealer.email))
                    //     .append($('<br/>'))
                    //     .append($('<small/>').text(dealer.latitude +', '+dealer.longitude))
                    // )
                );
            });
            $dealerSlider.slick({
                autoplay: false,
                arrows: false,
                slidesToShow: 3,
                dots: false,
                responsive: [
                    {
                      breakpoint: 992,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: true
                      }
                    }
                ]
            });//.resize();                       // resize() is necessary because the slider is inside a modal
            setTimeout(function(){
            //     console.log('resize timer');
                //$dealerSlider.resize();
                $dealerSlider.css({'opacity': 1, 'overflow-y':'visible' })
            }, 500);

        };
        
        $('#emailModal.modal').on('shown.bs.modal', function (e) {
            $dealerSlider.resize();
        });
        var activateDealerButtons = function(){
            
            $form.on('click', 'a.select-dealer', function(el){
                $this = $(el.target);
                var $thisDealer = $this.parent('.dealer');
                var $otherDealers = $thisDealer.siblings('.dealer');
                if ($otherDealers.length) {
                    $otherDealers.removeClass('selected');
                }
                $thisDealer.addClass('selected');                
                if ($('input#cc_me').attr('aria-expanded') == 'false'){
                    $('input#cc_me').trigger('click');
                }
                $form.find('input[name="dealer_id"]').val($this.data('id'));
                $form.find('button[type="submit"]').prop('disabled', false);
            })
        }


        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll('.needs-validation');
    
        // Loop over them and prevent submission
        Array.prototype.slice.call(forms).forEach(function (form) {
            form.addEventListener('submit', function (event) {
                event.preventDefault();
                event.stopPropagation();
                if (form.checkValidity()) {
                    submitForm($form);
                }
                form.classList.add('was-validated');
            }, false);
        });
        var submitForm = function($form){
            // console.log('submitForm() FORM SUBMITTED!! data: ', $form);
            var $inputFields = $form.find('input:not(:disabled),select:not(:disabled),textarea:not(:disabled)');

            $.ajax({   
                type: "POST",
                data : $inputFields.serialize(),
                //cache: false,  
                url: "/inc/phpmailer.php",
                success: function(data){
                    // console.log('submitForm() success.', data);
                    $('#emailModal').modal('hide');
                    $('.estimate-container').prepend($('<div/>', { class:'alert alert-success p-1 p-lg-2', role:'alert' }).text(__('Message sent.'))
                        .append($('<button/>', {class:'btn-close btn-close-white float-end', 'data-bs-dismiss':'alert', 'aria-label': 'Close' }))
                    );
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    console.log('submitForm() Error.', thrownError, xhr);
                    $('#emailModal').modal('hide');
                    $('.estimate-container').prepend($('<div/>', { class:'alert alert-danger p-1 p-lg-2', role:'alert' }).text(__('Sorry. There was an error. Message not sent.'))
                        .append($('<button/>', {class:'btn-close btn-close-white float-end', 'data-bs-dismiss':'alert', 'aria-label': 'Close' }))
                    );
                }
            });
        }


        

        

    }  // end - estimatePageHandler();
  
// --------------------------------------
// Only triggers functions, IF element exists...
// --------------------------------------
var $homepage = $('html').find('body.home');
if ($homepage.length) { $homepage.each( function(){ homePageHandler.call( this ); }); }

var $findpage = $('html').find('body.find');
if ($findpage.length) { $findpage.each( function(){ getAllUrlTranslations.call( this, 'find' ); }); }                   // gets all URL tranlations FIRST, then calls the pageHandler()

var $selectorpage = $('html').find('body.selector');
if ($selectorpage.length) { $selectorpage.each( function(){ getAllUrlTranslations.call( this, 'selector' ); }); }       // gets all URL tranlations FIRST, then calls the pageHandler()

var $customizepage = $('html').find('body.customize');
if ($customizepage.length) { $customizepage.each( function(){ getAllUrlTranslations.call( this, 'customize' ); }); }    // gets all URL tranlations FIRST, then calls the pageHandler()

var $estimatePage = $('html').find('body.estimate');
if ($estimatePage.length) { $estimatePage.each( function(){ getAllUrlTranslations.call( this, 'estimate' ); }); }



}); // end - $(document).ready()